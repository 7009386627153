<script setup lang="ts">
import { ref, computed, watch, onMounted, useSlots, nextTick } from 'vue'
import useSlotCheck from '@composable/useSlotCheck'

defineOptions({
  name: 'MdlCheckbox',
  inheritAttrs: false,
})

type Value = string | number | boolean

const props = withDefaults(
  defineProps<{
    name?: string
    value?: Value
    label?: string
    labelClass?: string | object | string[]
    description?: string
    indeterminate?: boolean
    disabled?: boolean
    readonly?: boolean
    checked?: boolean | Value[]
  }>(),
  {
    indeterminate: false,
    disabled: false,
    readonly: false,
    checked: false,
  },
)

const emit = defineEmits(['update:checked'])

const slots = useSlots()
const hasDefaultSlot = useSlotCheck(slots.default)

const input = ref<HTMLInputElement>()
const isChecked = ref(getChecked(props.checked))
const classNames = computed(() => ({
  'mdl-checkbox': true,
  'is-indeterminate': props.indeterminate,
  'is-checked': isChecked.value,
  'is-disabled': props.disabled,
  'is-upgraded': true,
  'w-auto': true,
}))

function getChecked(checked: boolean | Value[]) {
  if (Array.isArray(checked)) {
    return checked.includes(props.value)
  }
  return checked
}

function emitChange(checked: boolean) {
  let newValue: boolean | Value[] = checked

  if (Array.isArray(props.checked)) {
    newValue = [...props.checked]

    if (checked) {
      newValue.push(props.value)
      newValue = newValue.filter((value, index, arr) => arr.indexOf(value) === index)
    } else {
      newValue.splice(newValue.indexOf(props.value), 1)
    }
  }

  isChecked.value = checked
  emit('update:checked', newValue)
}

function onChange(event) {
  if (props.readonly) {
    event.preventDefault()
    return
  }

  emitChange(event.target.checked)
}

watch(
  () => props.checked,
  (value) => {
    isChecked.value = getChecked(value)
  },
  { immediate: true },
)

onMounted(() => {
  nextTick(() => {
    setTimeout(() => {
      if (input.value && input.value.checked !== isChecked.value) {
        this.emitChange(input.value.checked)
      }
    }, 50)
  })
})
</script>

<template>
  <label :class="classNames">
    <input
      ref="input"
      type="checkbox"
      class="mdl-checkbox__input"
      v-bind="$attrs"
      :name="name"
      :readonly="readonly"
      :disabled="disabled"
      :value="value"
      :checked="isChecked"
      @change="onChange"
    />
    <span v-if="hasDefaultSlot || label" class="mdl-checkbox__label" :class="labelClass">
      {{ label }}
      <slot></slot>
      <span v-if="description" class="mdl-checkbox__description">{{ description }}</span>
    </span>
    <span class="mdl-checkbox__box-outline"><span class="mdl-checkbox__tick-outline"></span></span>
  </label>
</template>
